<template>
  <div>
		<form @submit.prevent="getImages">
			<p>Fetch proctoring Hidden camera:</p>
			<input type="text" required v-model="nit" placeholder="NIT">
			<input type="number" required v-model="typeId" placeholder="test type id">
			<input type="number" required v-model="limit" placeholder="limit data to be fetched">
			<button type="submit" :disabled="loading">Fetch Images</button>
		</form>
		<div v-if="loading">Fetching data...</div>
		
		<div v-if="Object.keys(item).length">
			<details>
				<summary>
					<strong>{{ item.nit }}</strong> found {{ item.files.length }} proctoring images (Hidden Camera)
				</summary>
				<details>
					<summary>Image URL</summary>
					<div v-for="(file, ii) in item.files" :key="ii">
						&nbsp; {{ file.url }}
					</div>
				</details>

				<details @toggle="loadImages(item, $event)">
					<summary>Display Images</summary>
					<div>
						<form id="action-wrapper" @submit.prevent="sendFiles">
							<input type="text" required id="nit" placeholder="Enter New NIT"/>
							<input type="text" required id="company-code" placeholder="Enter Company Code"/>
							<input type="text" required id="assessment-code" placeholder="Enter assessment Code"/>
							<button type="submit">Send Files</button>
						</form>
						<hr>
						<div class="image-container">
							<div v-for="(file, iii) in item.files" :key="iii" >
								<img/> 
								<div>---</div>
							</div>
						</div>
					</div>
				</details>

				<details @toggle="getUploadedImages">
					<summary>Uploaded Images <small style="color: darkgray;">{{ loading? 'Loading...': '' }}</small></summary>

					<div class="image-container">
						<img :src="img" v-for="(img, ii) in uploadedImages" :key="ii"/>
					</div>
				</details>
			</details>
  	</div>
  </div>
</template>

<style>
	details{
		padding: 20px;
	}
	summary{
		border-bottom: 1px  solid;
		cursor: pointer;
	}
	.image-container{
		display: flex;
		flex-wrap: wrap;
	}
	.image-container img{
		margin: 3px;
		border: 1px  solid;
		width: 200px;
	}
	.image-container img.sending{
		border: 5px solid orange;
	}
	.image-container img.success{
		border: 5px solid lightgreen;
	}
	.image-container img.failed{
		border: 5px solid red;
	}
	form{
		padding: 20px;
	}
	form > *{
		margin-right: 5px;
	}
</style>
<script>
import { mapActions } from 'vuex'
export default{
	data(){
		return{
			loading: false,
			nit: 'CAPTURE2211171535Mb',
			typeId: 62,
			limit: 15,
			item: {},
			uploadedImages: [],
			isUploaded: false,
		}
	},
	methods:{
    ...mapActions({
      storeHiddenPhoto: 'newAnswer/storeHiddenPhoto',
    }),
		getImages(){
			this.loading = true
			this.item = {}
			let params = new URLSearchParams({
				nit: this.nit,
				type_id: this.typeId,
				limit: this.limit,
			})
			fetch(process.env.VUE_APP_BACKEND_URL + 
				'/api/v2/capture/proctoring-files?' +
				params.toString()
			)
			.then(response => response.json())
			.then(data => {
				this.item = {
					nit: this.nit,
					files: data
				}
			}).catch(error => {
				alert( error );
			})
			.then(() => this.loading = false)
		},
		getUploadedImages(e){
			if(e.target.open == false)  return
			
			this.loading = true
			this.uploadedImages = []

			let nit = document.getElementById('nit').value
			let ccode = document.getElementById('company-code').value
			let acode = document.getElementById('assessment-code').value

			let params = new URLSearchParams({
				nit: nit,
				ccode: ccode,
				acode: acode,
			})
			fetch(process.env.VUE_APP_BACKEND_URL + 
				'/api/v2/do-spaces/proctoring-files?' +
				params.toString()
			)
			.then(response => response.json())
			.then(data => {
				this.uploadedImages = data
			}).catch(error => {
				alert( error );
			})
			.then(() => this.loading = false)
		},
		loadImages(item, e){
			if(e.target.open){
				let images = document.querySelectorAll('.image-container img')
				images.forEach((img, i) => {
					let file =  item.files[i]
					img.src = 'data:image/jpeg;base64,'+file.base64
				})
			}
		},
		sendFiles(){
			let nit = document.getElementById('nit').value
			let ccode = document.getElementById('company-code').value
			let acode = document.getElementById('assessment-code').value
			let images = document.querySelectorAll('.image-container img')
			let totalUploadedImages = 0

			images.forEach((img, i) => {
				setTimeout(() => {
					let formData = new FormData()

					let image = nit + '||' + img.src
					formData.append('image', image)
					formData.append('nit', nit)
					formData.append('assessmentCode', acode)
					formData.append('companyCode', ccode)

					img.className = 'sending'
					this.storeHiddenPhoto(formData)
					.then(response => {
						img.className = 'success'

						console.log(img, response.data)
					})
					.catch(error => {
						img.className = 'failed'
						console.log(img, error.message || 'Unknown error')
					})
					.then(() => {
						let date = new Date()
						let h = date.getHours()
						let m = date.getMinutes()
						let s = date.getSeconds()
						let ms = date.getMilliseconds()

						img.nextSibling.innerText = h+':'+m+':'+s+':'+ms

						totalUploadedImages++
						if(totalUploadedImages == images.length) this.isUploaded = true
					})
				}, i * 2000);

			})
		},
	},
	mounted(){
	}
}
</script>